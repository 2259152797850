import ErrorBoundary from '@/pages/ErrorBoundary';
import { Root } from '@/pages/Root';
import { routes } from '@/routes';
import { PageLoading } from '@propify-tenant-client/common';
import type { PropifyRoute } from '@propify-tenant-client/layouts';
import { SentryService } from '@propify-tenant-client/services';
import { type FC, Suspense, useMemo } from 'react';
import { Navigate, Outlet, RouterProvider } from 'react-router';
import type { RouteObject } from 'react-router-dom';

const generateRoutes: (routesList: PropifyRoute[]) => RouteObject[] = (routesList) =>
  routesList.map((route) => ({
    path: route.path,
    element: route.redirect ? (
      <Navigate to={route.redirect} />
    ) : route.component ? (
      <Suspense fallback={<PageLoading />}>
        <route.component route={route} {...route.props} />
      </Suspense>
    ) : (
      <Outlet />
    ),
    children: !route.redirect && route.routes ? generateRoutes(route.routes) : undefined,
    ErrorBoundary,
  }));

export const Router: FC = () => {
  const router = useMemo(
    () =>
      SentryService.sentryCreateBrowserRouter([
        {
          path: '/',
          element: <Root />,
          children: generateRoutes(routes),
          ErrorBoundary,
        },
      ]),
    [],
  );

  return <RouterProvider router={router} />;
};
