import { SessionContextProvider } from '@propify-tenant-client/common';
import type { FC } from 'react';
import { useLayoutEffect } from 'react';
import ReactGA from 'react-ga';
import { Outlet, useLocation } from 'react-router';

export const Root: FC = () => {
  const location = useLocation();

  useLayoutEffect(() => {
    if (process.env.NODE_ENV === 'production') {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [location.pathname, location.search]);

  return (
    <SessionContextProvider>
      <Outlet />
    </SessionContextProvider>
  );
};
