import { Router } from '@/router';
import { MuiTheme } from '@/theme';
import { ThemeProvider } from '@/utils/material';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const App = () => {
  return (
    <ThemeProvider theme={MuiTheme}>
      <Router />
      <ToastContainer
        containerId="toast-container"
        position="top-center"
        autoClose={2500}
        hideProgressBar
        newestOnTop={false}
        closeOnClick={false}
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </ThemeProvider>
  );
};

export default App;
